<template>
  <div class="app-container">
    <div class="main-body">
      <el-row>
        <search-table
          ref="searchTable"
          labelWidth="70px"
          :loading="listLoading"
          :searchFields="searchFields"
          :autoBtnList="autoBtnList"
          :colSize="colSize"
          :total="total"
          :tableData="dataList"
          :tableColumns="tableColumns"
          :baseColumns="baseColumns"
          :sortFields="sortFields"
          :checkboxColumn="true"
          :hasPagination="true"
          :showFooter="true"
          :showSetting="false"
          :showColumnSearch="false"
          :showSortable="true"
          :tableSummary="tableSummary"
          :proxyParams="proxyParams"
          :selectTableSummary="selectTableSummary"
          :optionColumn="optionColumn"
          @saveTableField="saveTableField"
          @saveSearchField="saveSearchField"
          @saveSortField="saveSortField"
          @getTableData="queryList"
          @downloadSettleTable="downloadSettleTable"
          @downloadSettleBill="downloadSettleBill"
        >
        </search-table>
      </el-row>
    </div>
  </div>
</template>
<script>
import { orderSettleResult, settleDetailExport, settleOrderExport } from '@/api/order'
import SearchTable from '@/components/LcSearchTable'
import config from '@/const/settleDownload/index.js'
import { formatDate } from '@/utils/time'
import { columnSetting } from '@/api/common'
export default {
  components: {
    SearchTable
  },
  data: function () {
    return {
      total: 0,
      listLoading: false,
      queryParams: {
        operator: '',
        settleCode: '',
        settleType: ''
      },
      dataList: [],
      baseColumn: [], //接口请求的表格配置
      baseColumns: [],
      tableColumns: [], //用户自定义的表格配置
      searchFields: [], //用户自定义的search配置
      tableSummary: {}, //后端返回的总合计数据
      selectTableData: [], //checkbox选中的表格数据
      colSize: 3, //用户设置展示列比例
      selectTableSummary: config.selectTableSummary, // 需要选中合计的字段
      sortFields: [], // 用户自定义的排序设置
      optionColumn: {
        title: '操作',
        width: 200,
        fixed: 'right',
        btnList: [
          {
            title: '下载结算表格',
            fn: 'downloadSettleTable'
          },
          // {
          //   title: '下载结算凭证',
          //   fn: 'downloadSettleBill'
          // }
        ]
      },
      menuList: [],
      currentTenantId: '', // 当前设置权限的行租户id
      path: '' // 更新字段名显示接口路径
    }
  },
  created() {
    this.queryList()
    this.searchFields = config.searchFields(this)
    this.baseColumns = config.tableFields(this)
    this.tableColumns = config.tableFields(this)
  },
  computed: {
    // 操作按钮
    autoBtnList() {
      return config.operateList(this)
    }
  },
  mounted() {},
  methods: {
    // 下载结算表格
    downloadSettleTable(row) {
      let settleCode = row.settleCode
      let params = {
        id: row.id
      }
      settleDetailExport(params).then(response => {
        if (!response) {
          return
        }
        const url = window.URL.createObjectURL(new Blob([response]))
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = url
        link.setAttribute('download', settleCode + '.xls')
        document.body.appendChild(link)
        link.click()
      })
    },
    // 下载结算凭证
    downloadSettleBill(row) {
      let settleCode = row.settleCode
      let params = {
        operator: '',
        settleCode: settleCode,
        settleType: ''
      }
      settleOrderExport(params).then(response => {
        if (!response) {
          return
        }
        const url = window.URL.createObjectURL(new Blob([response]))
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = url
        link.setAttribute('download', settleCode + '.xls')
        document.body.appendChild(link)
        link.click()
      })
    },
    // 查询
    async queryList(params) {
      console.log('params', params)
      if (params) {
        this.queryParams = Object.assign(this.queryParams, params)
        // this.queryParams.current = params.current
        // this.queryParams.size = params.size
      } else {
        params = this.qeuryParams
      }
      this.listLoading = true
      this.baseColumns = config.baseColumn(this)
      const res = await orderSettleResult(this.queryParams)
      if (res.code === 200) {
        this.dataList = res.data.records
        this.total = res.data.total * 1
        // this.tableSummary = res.data.summary
        // this.baseColumns = res.data.tableSettingFields || config.baseColumn(this)
        this.baseColumns = config.baseColumn(this)
        // this.path = res.data.path || this.path
        // this.colSize = res.data.colSize
        // this.initConfig()
      }
      this.listLoading = false
    },
    initConfig() {
      this.tableColumns = this.baseColumns.filter(v => v.showField)
      this.searchFields = this.baseColumns.filter(v => v.isSearchShow)
      this.sortFields = this.tableColumns
        .filter(v => v.sortable)
        .reduce((prev, item) => {
          // if(item.sortList) prev = prev.concat(item.sortList)
          prev.push({
            title: `${item.title}升序`,
            field: `${item.field}-asc`
          })
          prev.push({
            title: `${item.title}降序`,
            field: `${item.field}-desc`
          })
          return prev
        }, [])
    },
    // 时间格式化
    dateFormat(data) {
      return formatDate(data)
    },
    //保存|修改表格头配置
    async saveTableField(fields) {
      this.tableColumns = fields
      try {
        const res = await columnSetting({
          path: this.path,
          // searchFields: this.searchFields,
          tableFields: fields
        })
        this.getTableData()
      } catch (error) {
        console.log(error)
      }
    },
    //保存|修改搜索字段配置
    async saveSearchField(fields, colSize) {
      // console.log(fields, colSize)
      this.colSize = colSize
      this.searchFields = fields
      try {
        const res = await columnSetting({
          path: this.path,
          searchFields: fields,
          // tableFields: this.tableColumns,
          colSize
        })
      } catch (error) {
        console.log(error)
      }
    },
    //保存|修改排序字段配置
    async saveSortField(fields) {
      this.sortFields = fields
    },
    // 处理数据
    proxyParams(params) {
      console.log(params)
    },

    //获取表格头配置
    async getTableField() {},
    //获取搜索字段配置
    async getSearchField() {}
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.per-grid {
  float: left;
  padding: 10px;
  // border: 1px solid #f0f0f0;
  border-radius: 2;
  // box-shadow: 1px 0 0 0 #f0f0f0, 0 1px 0 0 #f0f0f0, 1px 1px 0 0 #f0f0f0,
  //   1px 0 0 0 #f0f0f0 inset, 0 1px 0 0 #f0f0f0 inset;
  transition: all 0.3s;
  width: 50% !important;
}
.input-cls {
  width: 260px;
  font-size: 14px;
  // feat: 搜索栏过多收起
  margin-right: 10px;
}
.icon-cls-green {
  color: #67c23a;
}
.dialog-row {
  margin-top: 10px;
}
</style>

<style rel="stylesheet/scss" lang="scss" scoped>
.validFieldClass .el-input__inner {
  border-color: #f56c6c;
}
.el-pagination {
  float: right;
}
.sortable-column-demo .vxe-header--row .vxe-header--column.sortable-ghost,
.sortable-column-demo .vxe-header--row .vxe-header--column.sortable-chosen {
  background-color: #dfecfb;
}
.vxe-table--footer .col-blue {
  background: #f2f5f0;
}
/*单号复制样式 开始*/
.copy-content {
  right: 0.1em;
  top: 0.1em;
  float: right !important;
  position: absolute !important;
}
.copy-content:hover {
  background-color: #1482f0;
  float: right !important;
  height: 1.8em;
  width: 1.8em;
}
.write-color {
  border-color: #f0f0f0;
}
/*单号复制样式 结束*/
.input-width-6 {
  width: 60% !important;
}
.input-width-7 {
  width: 70% !important;
}
.input-width-8 {
  width: 80% !important;
}
</style>
