// 操作按钮配置项
const operateList = vm => {
  return []
}
const baseColumn = vm => {
  return [
    {
      keyId: 1,
      title: '结算编号',
      field: 'settleCode',
      width: null,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      selectOptions: null,
      showField: true,
      enum: false,
      fieldEnumValueList: null,
      fieldOperateTag: null,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },
    {
      keyId: 2,
      title: '结算类型',
      field: 'settleType',
      width: null,
      fieldType: null,
      searchType: 'select',
      searchValue: '',
      showField: true,
      selectOptions: [
        {
          label: '工地结算',
          value: 'WORK'
        },
        {
          label: '渣土场结算',
          value: 'MUCK'
        }
      ],
      enum: true,
      fieldOperateTag: {
        WORK: 'success',
        MUCK: 'success'
      },
      fieldEnumValueList: {
        WORK: '工地结算',
        MUCK: '渣土场结算'
      },
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: 'status',
      copy: true
    },
    {
      keyId: 3,
      title: '结算时间',
      field: 'settleDate',
      width: null,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      selectOptions: null,
      showField: true,
      enum: false,
      fieldEnumValueList: null,
      fieldOperateTag: null,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },
    {
      keyId: 4,
      title: '结算工单数',
      field: 'count',
      width: null,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      selectOptions: null,
      showField: true,
      enum: false,
      summable: true,
      fieldEnumValueList: null,
      fieldOperateTag: null,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },
    {
      keyId: 5,
      title: '扫描结算操作人',
      field: 'operator',
      width: null,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      selectOptions: null,
      showField: true,
      enum: false,
      fieldEnumValueList: null,
      fieldOperateTag: null,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },
    {
      keyId: 5,
      title: '扫描结算对象',
      field: 'settler',
      width: null,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      selectOptions: null,
      showField: true,
      enum: false,
      fieldEnumValueList: null,
      fieldOperateTag: null,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    }
  ]
}

const tableFields = vm => {
  return [
    {
      keyId: 1,
      title: '结算编号',
      field: 'settleCode',
      width: null,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      selectOptions: null,
      showField: true,
      enum: false,
      fieldEnumValueList: null,
      fieldOperateTag: null,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },
    {
      keyId: 2,
      title: '结算类型',
      field: 'settleType',
      width: null,
      fieldType: null,
      searchType: 'select',
      searchValue: '',
      showField: true,
      selectOptions: [
        {
          label: '工地结算',
          value: 'WORK'
        },
        {
          label: '渣土场结算',
          value: 'MUCK'
        }
      ],
      enum: true,
      fieldOperateTag: {
        WORK: 'success',
        MUCK: 'success'
      },
      fieldEnumValueList: {
        WORK: '工地结算',
        MUCK: '渣土场结算'
      },
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: 'status',
      copy: true
    },
    {
      keyId: 3,
      title: '结算时间',
      field: 'settleDate',
      width: null,
      fieldType: null,
      searchType: 'datetimerange',
      searchValue: '',
      selectOptions: null,
      showField: true,
      enum: false,
      fieldEnumValueList: null,
      fieldOperateTag: null,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },
    {
      keyId: 4,
      title: '结算工单数',
      field: 'count',
      width: null,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      selectOptions: null,
      showField: true,
      summable: true,
      enum: false,
      fieldEnumValueList: null,
      fieldOperateTag: null,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },
    {
      keyId: 5,
      title: '扫描结算操作人',
      field: 'operator',
      width: null,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      selectOptions: null,
      showField: true,
      enum: false,
      fieldEnumValueList: null,
      fieldOperateTag: null,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },
    {
      keyId: 5,
      title: '扫描结算对象',
      field: 'settler',
      width: null,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      selectOptions: null,
      showField: true,
      enum: false,
      fieldEnumValueList: null,
      fieldOperateTag: null,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    }
  ]
}

const selectTableSummary = [
  {
    keyId: 4,
    field: 'count'
  }
]

// 搜索字段配置项
const searchFields = vm => {
  return [
    {
      keyId: 1,
      title: '管理员',
      field: 'operator',
      width: null,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      selectOptions: null,
      showField: true,
      enum: false,
      fieldEnumValueList: null,
      fieldOperateTag: null,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },
    {
      keyId: 2,
      title: '结算批次号',
      field: 'settleCode',
      width: null,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      selectOptions: null,
      showField: true,
      enum: false,
      fieldEnumValueList: null,
      fieldOperateTag: null,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },
    {
      keyId: 3,
      title: '结算类型',
      field: 'settleType',
      width: null,
      fieldType: null,
      searchType: 'select',
      searchValue: '',
      selectOptions: null,
      showField: true,
      selectOptions: [
        {
          label: '工地结算',
          value: 'WORK'
        },
        {
          label: '渣土场结算',
          value: 'MUCK'
        }
      ],
      enum: true,
      fieldOperateTag: {
        MUCK: 'success',
        WORK: 'success'
      },
      fieldEnumValueList: {
        WORK: '工地结算',
        MUCK: '渣土场结算'
      },
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },
    {
      keyId: 4,
      title: '结算时间',
      field: 'settleDate',
      width: null,
      fieldType: null,
      searchType: 'datetimerange',
      searchValue: '',
      selectOptions: null,
      showField: true,
      enum: false,
      fieldEnumValueList: null,
      fieldOperateTag: null,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },
  ]
}

export default {
  operateList,
  searchFields,
  tableFields,
  baseColumn,
  selectTableSummary
}
